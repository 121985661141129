<template>
    <div class="d-flex flex-row ">
        <div class="box" :style="'background-color:' + color" ></div>
        <div class="text">{{ text }}</div>
    </div>
</template>
<script>
export default {
    name:"BarChartLegendItem",
    props:{
        text:{
            type: String,
            required: true
        },
        color:{
            type: String,
            required: true
        }
    }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    font-weight: 400;
    color: black;
}

.theme--dark .text{
    color: #EBEAFA;
}
.box{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}
</style>