<template>
    <div :style="cssVars" class="myContainer">
        <div class="d-flex flex-row" >
            <div class="minMax text-right">{{ prunNum(min) }}</div>
            <div class="gradientBox">
                <div class="gradientInnerBox"> &nbsp;</div>
            </div>
            <div class="minMax text-left">{{ prunNum(max) }}</div>
        </div>
        <div style="position:relative">
            <div class="indicator">{{ prunNum(val) }}</div>
        </div>
    </div>
</template>
<script>
import {smartPruneNumber} from '@/utils/number'

export default {
    name: 'CastingExploreResultStatistics',
    props:{
        min: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            required: true
        },
        val: {
            type: Number,
            required: true
        },
        width:{
            type: Number,
            default: 300
        }
    },
    methods:{
        prunNum(num){
            return smartPruneNumber(num)
        }
    },
    data:()=>({
        minMaxWidth:50,
        indicatorWidth:70,
        gradientBoxXMargin:4,
    }),
    computed:{
        innerWidth(){
            const bias = 0
            let res = 0
            if (this.min == this.max || this.val == this.min || this.val < this.min){
                res = 0
            } else if ( this.val > this.max) {
                res = this.width
            } else
            {
                res =  ((this.val - this.min) * this.width)/(this.max - this.min)
            }
            return res + bias
        },
        indicatorPosition(){
            console.log(this.minMaxWidth - this.indicatorWidth/2 + this.innerWidth)
            return this.minMaxWidth - this.indicatorWidth/2 + this.innerWidth + this.gradientBoxXMargin
        },
        cssVars(){
            return { 
                '--box-width': this.width + 'px' ,
                '--innerbox-width':this.innerWidth + 'px' ,
                '--min-max-width': this.minMaxWidth + 'px',
                '--indicator-position': this.indicatorPosition + 'px',
                '--indicator-width': this.indicatorWidth + 'px',
                '--gradient-box-x-margin': this.gradientBoxXMargin + 'px'
            }
        }
    }
}
</script>
<style scoped>

.myContainer{
    height:60px;
    /* margin-bottom:5px; */
}
.gradientBox{
    border: 1px solid var(--v-primary-base);
    margin-left: var(--gradient-box-x-margin);
    margin-right: var(--gradient-box-x-margin);
    width: var(--box-width);
    height: 20px;
    background: linear-gradient(90deg, #E3FBFF 0%, #1098AC 100%);

}
.gradientInnerBox{
    border-right: 1px solid var(--v-primary-base);
    width: var(--innerbox-width);
    height: 19px;

    /* width: 200px; */
}
.minMax{
    color:grey;
    font-size: 10px;
    width: var(--min-max-width);
    padding-top:2px;
    /* border: 1px solid red; */
}
.indicator{
    margin-top:8px;
    background-color: rgba(3, 132, 151, 1);
    border-radius: 6px;
    height: 19px;
    width: var(--indicator-width);
    color: white;
    font-size: 12px;
    position: absolute;
    left: var(--indicator-position)
}
 .indicator::before {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -4px;
  border-width: 5px;
  border-style: solid;
  border-color:  transparent  transparent var(--v-primary-base) transparent;
}
</style>