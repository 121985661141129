<template>
    <div>
        <v-rect :config="markerBox"></v-rect>
        <v-text :config="markerText"></v-text>
        <v-line :config="line"></v-line>
    </div>
</template>
<script>
export default {
    name:'BarChartMarker',
    data:()=>({
        canvas: null,
    }),
    methods:{
        displayTextWidth(text) {
            const font = 'normal 12px IBM Plex sans'
            let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
            let context = canvas.getContext("2d");
            context.font = font;
            let metrics = context.measureText(text);
            return metrics.width;
        }
    },
    computed:{
        effectiveMarkerHeight(){
            return this.markerHeight - 2
        },
        markerText(){
            return {
                    x: this.from.x,
                    y: Math.max( this.from.y - this.effectiveMarkerHeight,0) - 2  ,
                    text: this.name,
                    fontSize: 12,
                    fontFamily: 'IBM Plex sans',
                    fill: this.isSelected ? 'white' : 'black',
                    // width: 70,
                    padding: 6,
                    // textColor: 'red',
                    // align: 'right',
            }    
        },
        markerBox(){
            return {  
                x: this.from.x,
                y: Math.max(this.from.y - this.effectiveMarkerHeight, 0 ),
                width: this.displayTextWidth(this.name) + 12,
                height: this.effectiveMarkerHeight,
                fill: this.color,
                cornerRadius:  this.isSelected ? [6, 6, 6, 6] : [6, 6, 0, 0],
            }
        },
        line(){
            return {
                x: 0 ,
                y: 0 ,
                points: this.dir=='LEFT' ? [ this.isSelected ? this.from.x + this.displayTextWidth(this.name) + 10 : this.from.x , 
                          this.isSelected ? this.from.y -10: this.from.y , 
                         this.from.x + this.displayTextWidth(this.name) + 20 , 
                          this.isSelected ? this.from.y -10: this.from.y , 
                         this.to.x , 
                         this.to.y +1,  
                         this.to.x + 45 , 
                         this.to.y +1 ] : [
                            this.from.x + this.displayTextWidth(this.name) + 10, 
                            this.from.y , 
                            this.from.x - 10 , 
                            this.from.y , 
                            this.to.x , 
                            this.to.y +1,  
                            this.to.x - 40 , 
                            this.to.y +1
                         ],
                stroke: this.isSelected ? '#038497' : (this.$vuetify.theme.isDark? 'white' :'black'),
                strokeWidth: 0.4,
                tension: 0.1,
                dash: this.isSelected ? [0] : [3, 3]
            } 
        }
    },
    props:{
        isSelected:{
            Type: Boolean,
            default: false
        },
        markerHeight:{
            Type: Number,
            default: 20
        },
        name:{
            type: String,
            required: true
        },
        to:{
            type: Object,
            required: true
        },
        from:{
            type: Object,
            required: true
        },
        dir:{
            type:String, 
            default: 'LEFT'
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>