import ApiIntegrface from "./api"

export function getDatasets(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets'
  api.get(url, null, callback, null, null, null)
}


export function getDataset(datasetId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId
  api.get(url, null, callback,null, null, null, false)
}


export function getDatasetTypes(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasettypes'
  api.get(url, null, callback, null)
}

export function addDataset(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets'
  api.post(url, payload, callback, null, successMessage)
}

export function updateDataset(payload, datasetId, successMessage){

  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/'+ datasetId
  api.put(url, payload, null, null, successMessage)
}


export function updateDatasetStatus(datasetId, payload, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/status"
  api.put(url, payload, callback, null)
}

export function postprocessDataset(datasetId, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/postprocess"
  api.put(url, null, null, null, successMessage)
}

export function deleteDataset(datasetId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId
  api.delete(url, null, callback, null, successMessage)
}

export function getDatasetFields(datasetId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, null,callback, null)
}

export function getDatasetFieldsByGroup(datasetId, groups, callback){
  const api = new ApiIntegrface()
  const params = {
    groups : groups.toString()
  }
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, params,callback, null)
}
export function getDatasetFieldsByOneOfGroups(datasetId, groups, callback){
  const api = new ApiIntegrface()
  const params = {
    hasoneofgroups : groups.toString()
  }
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, params,callback, null)
}

export function addDatasetFieldsToGroup(datasetId,payload,  callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields/groups"
  api.put(url, payload, callback, null, successMessage)
}

export function removeDatasetFieldsFromGroup(datasetId,payload,  callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields/groups"
  api.delete(url, payload, callback, null, successMessage)
}

export function getPackages(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/packages'
  api.get(url, null,callback, null)
}