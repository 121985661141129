import ApiIntegrface from "../api"
import store from '@/store'
export function getPackages(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages'
  api.get(url, null, callback)
}

export function getDatasets(packageId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages/' + packageId + '/datasets'
  api.get(url, null, callback)
}

export function getData(packageId, datasetId, filters, column_groups, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages/' + packageId + '/datasets/' + datasetId + '/search'
  const payload = {
    filters: filters,
    column_groups: column_groups
  }
  api.post(url, payload, callback, null, null)
}

export function getProjection(packageId, datasetId, columns,filters,columnFilters, standardDatasetId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages/' + packageId + '/datasets/' + datasetId + '/explore'
  const payload = {
     filters: filters,
     columns: columns,
     stats:["AVG","MIN","MAX"],
     columnFilters: columnFilters,
     standardDatasetId:standardDatasetId
  }
  api.post(url, payload, callback, null, null, null, false)

}

export function getFilteredAggregations(packageId, datasetId, columns,filters, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages/' + packageId + '/datasets/' + datasetId + '/filteredAggregations'
  const payload = {
     filters: filters,
     columns: columns
  }
  api.post(url, payload, callback, null, null,null, false)
}


export function getAggregations(packageId, datasetId, fields, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/packages/' + packageId + '/datasets/' + datasetId + '/aggregations'
  const payload = {
    fields: fields,
  }
  api.post(url, payload, callback, null, null)
}

