<template>
    <div class="pl-2 pb-3 chartLegend">
        <BarChartLegendItem class="mt-3 mx-2"  v-for="s in series" :key="s.name" :color="s.color" :text="s.name"></BarChartLegendItem>
    </div>
</template>
<script>
import BarChartLegendItem from '@/components/Predix/BarChartLegendItem.vue';
export default {
    name:'BarChartLegend',
    components:{BarChartLegendItem},
    props:{
        series:{
            type: Array,
            default: ()=> []
        }
    }
}
</script>
<style scoped>
.chartLegend{
  border-radius: 6px 0px 0px 6px;
  background-color: white;
  width: 150px;
}
.theme--dark .chartLegend{
  border-radius: 6px 0px 0px 6px;
  background-color: #18191AB2;
  width: 150px;
}


</style>