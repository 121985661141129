import ApiIntegrface from "./api"
export function getFieldGroups(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups'
  api.get(url, null, callback, null, null, null)
}

export function addFieldGroup(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups'
  api.post(url, payload, callback, null, successMessage)
}

export function editFieldGroup(id, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups/' + id
  api.put(url, payload, callback, null, successMessage)
}

export function editFieldGroupOrder( payload, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups/order'
  api.put(url, payload, null, null, successMessage)
}

export function deleteFieldGroup(groupId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgroups/' + groupId
  api.delete(url, null, callback, null, successMessage)
}