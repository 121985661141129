
<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :height="height"
    solo
    flat
    :menu-props="{offsetY:true}"
    append-icon="mdi-chevron-down"
    hide-details="true"
    class="mb-3"
  >
    <template v-if="icon" #prepend-inner>
      <icon :id="icon"></icon>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CSelect',
  props:{
    icon: {
      type: String,
      default: null
    },
    height:{
      type: Number,
      default: 48
    }
  }
}

</script>
<style scoped>
/* .v-input {
  color: black !important;
  font-weight: 700 !important;
  font-size: 18px !important;
} */
::v-deep .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  box-shadow: 0px 4px 12px  rgba(0, 0, 0, 0.14);
  /* box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07); */

}

/* .v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: black !important;
  font-weight: 700;
  font-size: 18px;
}
.v-icon {
  color: black;
} */
/* .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14);
} */
/* .theme--light.v-label{
  color: black !important;;
  font-weight: 700;
  font-size: 18px;

} */

</style>