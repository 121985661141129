<template>
  <div class="mt-6 pt-0" style="width:100%;position: relative;">

      <div class="d-flex justify-space-between flex-row">
        <div v-if="databaseType==1" class=" text-h4 ">
          COMPOSITION
        </div>
        <div v-else class="d-flex justify-start">
          <div class="v-label mt-2 mr-3  font-weight-bold">STANDARD ALLOY </div>
          <CSelect class="mt-0 pt-0" :height="35" dense :items="standardAlloys" v-model="selectedStandardAlloy" item-text="name" item-value="id" @change="loadStandardAlloyProps()" single-line></CSelect>
        </div>
        <v-radio-group class="mt-0" row v-model="compositionType" mandatory @change="onPropertiesTypeChanged()">
          <template v-slot:label>
            <div class="v-label">Alloy composition in</div>
          </template>
          <v-radio value="COMPOSITION_AT" >
            <template v-slot:label>
              <div class="compositionRadio"> at%</div>
            </template>
          </v-radio>
          <v-radio value="COMPOSITION_WT">
            <template v-slot:label>
              <div class="compositionRadio"> wt%</div>
            </template>
          </v-radio>
        </v-radio-group>

      </div>

      <div v-if="databaseType==2">
        <v-row v-for="(n,i) in Math.ceil(standardAlloyProperties.length / 4)" :key="i" >
          <v-col v-for="prp in standardAlloyProperties.slice(i*4,(i+1)*4) " :key="prp.id">
            <v-row class="mt-2">
              <v-col class="pr-0" cols="3"><span class="v-label text--secondary ">{{prp.id}} </span></v-col>
              <v-col class="pl-0" cols="9"><span class="numbers black--text">{{prunNum(prp.value)}}</span></v-col>
            </v-row>
          </v-col>
          <template v-if="(i == Math.ceil(standardAlloyProperties.length / 4) - 1 )">
            <v-col v-for="(x,j) in standardAlloyProperties.length % 4" :key="j"></v-col>
          </template>

        </v-row>
      </div>
      <div v-if="databaseType==1" >
        <v-row class="my-n3" v-for="(n,i) in Math.ceil(datasetCompositionFields.length / 4)" :key="i" >
          <v-col  v-for="prp in datasetCompositionFields.slice(i*4,(i+1)*4) " :key="prp.id">
            <div >
              <CTextField :border="'1px'"  height="48px" hide-details v-if="prp.groups.findIndex(g => g.groupType === 'ALUMINIUM') !== -1"  class="pt-0" v-model="selectedProperties[prp.name]" disabled :cLabel="prp.title"></CTextField>
              <CTextField :border="'1px'" :changed="(selectedProperties[prp.name]?true:false)" height="48px" hide-details v-else class="pt-0" :rules="[ v => (( v === undefined  || v === null || v === '' ) || (Number(v) >= prp.stats.min && Number(v) <= prp.stats.max)) ]"  :value="selectedProperties[prp.name]" @change="onPropValueChange(prp, $event)" :cLabel="prp.title"></CTextField>
              <div class="d-flex justify-space-between  text-caption number grey--text text--darken-1 mt-1">
                <div> {{prunNum(prp.stats.min)}} </div>
                <div>{{prunNum(prp.stats.max)}}</div>
              </div>
            </div>
          </v-col>
          <template v-if="(i == Math.ceil(datasetCompositionFields.length / 4) - 1 )">
            <v-col v-for="(x,j) in datasetCompositionFields.length % 4" :key="j"></v-col>
          </template>
        </v-row>
      </div>
    <div class="d-flex justify-space-between mt-11">
      <v-radio-group class="mt-0" row v-model="selectedHeatTreatment" mandatory>
        <template v-slot:label>
          <div class="v-label">Heat treatment type</div>
        </template>
        <v-radio v-for="item in heatTreatmentTypes" :key="item.id" :value="item.id">
          <template v-slot:label>
            <div class="compositionRadio"> {{item.name}}</div>
          </template>
        </v-radio>
      </v-radio-group>
      <div class="d-flex justify-start ">
        <div class="v-label mt-2 mr-3 grey--text text--darken-2">Casting</div>
        <CSelect :height="32" class="mt-0 pt-0 " dense :items="castingGroups" v-model="selectedCastingGroups" item-text="name" item-value="id" single-line></CSelect>
      </div>
    </div>
    <div class="d-flex">
        <CBtn color="primary" class="mx-auto px-8" :disabled="databaseType === 2 && selectedStandardAlloy === -1" prepen @click="onExplore()">
          <v-icon left>
            mdi-magnify
          </v-icon>          
          Look Up
        </CBtn>
    </div>
    </div>

</template>
<script>
import { getData} from '@/api/user/dataset'
import {getDatasetFieldsByOneOfGroups} from '@/api/dataset'
import { smartPruneNumber } from '@/utils/number'
import CTextField from '@/components/Vuetify/CTextField.vue'
import CSelect from '@/components/Vuetify/CSelect.vue'
import CBtn from '@/components/Vuetify/CBtn.vue'
// import {loadDatasetFields} from '@/api/settings'
// import VueApp from '@/main'

export default {
  // Al at% 99.493
  // Co at%	0.507
  name:'AlloySelector',
  components:{CTextField, CSelect, CBtn},
  props:{
      databaseId: {
        type: Number,
        default: -1
      },
      databaseType:{
        type: Number,
        default:1
      },
      fieldGroups:{
        type: Array,
        default: () =>[]
      },
      package_id:{
        type: Number,
        default: 0
      },
      standardAlloys:{
        type: Array,
        default: () => []
      }
  },

    data: () => ({
      compositionType: 'COMPOSITION_AT',

      heatTreatmentTypes:[],
      selectedHeatTreatment: 0,
      castingGroups:[],
      selectedCastingGroups:0,

      selectedProperties:{},
      selectedStandardAlloy: -1,
      standardAlloyProperties:[],

      datasetFields:[],
      datasetCompositionFields:[],

      rules: {
        isNumber: v => (! isNaN(v) || v === undefined  || v === null || v === "" )
      }
    }),
    created() {
      if( this.databaseId >= 0 ){
        // this.heatTreatmentTypes = this.fieldGroups.filter(item => item.groupType === 'HEAT TREATMENT')
        // this.castingGroups = this.fieldGroups.filter(item => item.groupType === 'CASTING')
        // if (this.heatTreatmentTypes.length > 0){
        //     this.selectedHeatTreatment = this.heatTreatmentTypes[0].id
        // }
        // if (this.castingGroups.length > 0){
        //   this.selectedCastingGroups = this.castingGroups[0].id
        // }
        // if( this.databaseType === 1 ){
          this.loadDatasetFields()
        // }
      }
    },
    watch:{
      databaseId(newVal, oldVal){
        if(oldVal == -1 && newVal !== -1){
          // this.heatTreatmentTypes = this.fieldGroups.filter(item => item.groupType === 'HEAT TREATMENT')
          // this.castingGroups = this.fieldGroups.filter(item => item.groupType === 'CASTING')
          // if (this.heatTreatmentTypes.length > 0){
          //     this.selectedHeatTreatment = this.heatTreatmentTypes[0].id
          // }
          // if (this.castingGroups.length > 0){
          //   this.selectedCastingGroups = this.castingGroups[0].id
          // }
        }
        this.reset()
        // if( this.databaseType === 1){
            this.loadDatasetFields()
        // }
      }
    },
    methods: {
      reset(){
        this.selectedProperties = {}
        this.exploreResult = []
        this.datasetFields = []
        this.selectedStandardAlloy = 0
        this.standardAlloyProperties = []
        this.datasetCompositionFields = []
        this.heatTreatmentTypes=[]
        this.castingGroups =[]
      },
      onExplore(){
        this.loadResult()
      },


      getIndicatorPosition(min, max, value, length){
        const bias = 10
        if (min == max || value == min){
          return bias
        } else if ( value > max) {
          return bias + length
        } else if ( value < min) {
          return bias
        } else
        {
          return bias +  (value * length)/(max - min)
        }
      },
      prunNum(num){
        return smartPruneNumber(num)
      },
      onPropValueChange(prp, val){
        val = Number(val)
        if ( isNaN(val)){
          return
        }
        this.$set(this.selectedProperties,prp.name,val)
        const alluminum = this.datasetCompositionFields.find(prp =>  prp.groups.findIndex(g => g.groupType === 'ALUMINIUM') !== -1)
        var sum = 0
        for ( const alloy_name of Object.keys(this.selectedProperties)){
          if (alloy_name !== alluminum.name){
            sum += this.selectedProperties[alloy_name]
          }
        }
        this.$set(this.selectedProperties, alluminum.name, 100 - sum)

      },
      onPropertiesTypeChanged(){
        this.loadDatasetFields()
        // }
        // else{
        if(this.databaseType == 2){
          this.loadStandardAlloyProps()
        }
      },
      loadStandardAlloyProps(){
          console.log('loadStandardAlloyProps')
          const _this = this
          const alloyNameGroupId = this.fieldGroups.find(item => item.groupType === this.compositionType).id
          getData(this.package_id, this.databaseId, [ {"f0": this.selectedStandardAlloy}], [alloyNameGroupId], function(res){
            const alloy = res.result[0]
            _this.standardAlloyProperties.forEach(element => { element.value = alloy[element.id] });
            // _this.standardAlloyProperties=[]
            // for( var key of Object.keys(alloy).sort()){
              // _this.standardAlloyProperties.push({"id":key,"value":alloy[key]})
            // }
          })
      },
      loadDatasetFields(){
        const _this = this
        const alloyNameGroupId = this.fieldGroups.find(item => item.groupType === this.compositionType).id
        const groupTypes = ['ALUMINIUM', 'CASTING' , 'HEAT TREATMENT','COMPOSITION_AT','COMPOSITION_WT']
        const groups =  this.fieldGroups.filter(g => groupTypes.includes(g.groupType)).map(item => item.id)
        getDatasetFieldsByOneOfGroups(this.databaseId, groups, function(res){
          _this.datasetFields = res
          // _this.datasetFields = res.filter(f => f.groups.filter(g => groupTypes.includes(g.groupType)).length > 0)
          let groups = new Set()
          let heatTreatmens = new Set()
          _this.datasetFields.forEach(item => item.groups.filter(grp => grp.groupType === 'CASTING').forEach(g => groups.add(g.id)))
          _this.datasetFields.forEach(item => item.groups.filter(grp => grp.groupType === 'HEAT TREATMENT').forEach(g => heatTreatmens.add(g.id)))
          _this.castingGroups = _this.fieldGroups.filter(item => groups.has(item.id))
          _this.heatTreatmentTypes = _this.fieldGroups.filter(item => heatTreatmens.has(item.id))


          if (_this.heatTreatmentTypes.length > 0){
              _this.selectedHeatTreatment = _this.heatTreatmentTypes[0].id
          }
          if (_this.castingGroups.length > 0){
            _this.selectedCastingGroups = _this.castingGroups[0].id
          }


          _this.datasetCompositionFields = res.filter(item => item.groups.findIndex(grp => grp.id === alloyNameGroupId) !== -1).sort((a,b) => (a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)) )
          _this.standardAlloyProperties = _this.datasetCompositionFields.map(item => { return {id:item.title, value: null} })
          
          const al = _this.datasetCompositionFields.find(prp =>  prp.groups.findIndex(g => g.groupType === 'ALUMINIUM') !== -1)
          _this.selectedProperties[al.name] = 100

        })
      },
      loadResult(){
        const _this = this
        _this.$emit('onExploreStart')

        var groups = [ _this.selectedCastingGroups, _this.selectedHeatTreatment ]
        var datasetId = 0
        var filters = []
        if(this.databaseType == 1){ // Normal
          datasetId = this.databaseId
          filters = this.datasetCompositionFields.map(
            item => {
              var r ={}
              r[item.name] = ( isNaN(this.selectedProperties[item.name]) ? 0 : Number(this.selectedProperties[item.name]))
              return r
            }
          )

        }else{ // Standard
          datasetId = this.databaseId
          filters =  [ {"f0": this.selectedStandardAlloy}]
        }
        getData(_this.package_id, datasetId, filters, groups, function(res){
          if ( res.result.length == 0){
            //_this.$root.notify.show({message: "No result has found! change the parameters!", type: "error"})
            _this.$emit('onExplore', [],[], 0, 0, _this.databaseType)
            return
          }
            const aggs = res.aggregation
            const rs = res.result[0]
            var exploreResult=[]
            for( var key of Object.keys(rs)){
              let f = _this.datasetFields.find(f =>f.title == key)
              exploreResult.push({"name":key,"value":rs[key], "stats":aggs.find(item => Object.keys(item)[0] === key)[key], "id": f.id, "order": Number(f.name.slice(1)), "groups": f.groups.map(g=>g.id)})
            }
            exploreResult.sort((a,b) => a.order-b.order)
            _this.$emit('onExplore', exploreResult, _this.datasetFields, _this.selectedHeatTreatment, _this.selectedCastingGroups, _this.databaseType)
        })

      }
  }
}
</script>
<style scoped>

.compositionRadio{
  color: black !important;
}
.theme--dark .compositionRadio{
  color: #EBEAFA !important;

}

.theme--dark .v-label{
  color:#6B7588 !important;
}

.numbers {
  color:rgba(107, 117, 136, 1);
}
.blured {
  filter: blur(1px);
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.v-data-table-header {
  background-color: rgba(182, 183, 187, 0.9);
  color: white;
}
</style>