<template>
  <!-- <v-tooltip :disabled="tooltip" top> -->
    <!-- <template v-slot:activator="{ ona, attrsa }"> -->
      <!-- <div v-bind="attrsa" v-on="ona"> -->
        <v-btn elevation="0" v-bind="$attrs" v-on="$listeners" :color="color" :style="'border-radius:' + radius + ';'">
          <slot />
        </v-btn>
      <!-- </div> -->
    <!-- </template> -->
    <!-- <span>{{ tooltip }}</span> -->
  <!-- </v-tooltip> -->
</template>

<script>
export default {
  name: "CBtn",
  props: {
    radius: { type: String, default: "8px" },
    color: { type: String, default: "primary" },
    // tooltip: {type: String, default:null}
  },
  // compouted:{
  //   hoverColor(){
  //     switch(this.color){
  //       case 'primary':
  //         return 'primaryHover';
  //       case 'neutral':
  //         return 'neutralHover';
  //       default:

  //     }
  //   }
  // }
};
</script>
<style scoped>
.v-btn:hover {
  filter: brightness(95%) !important;
}
/* .v-btn:focus{
  filter:brightness(9) !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
} */
</style>