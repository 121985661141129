<template>
  <div>
    <v-row class="mt-3 mx-3">
      <v-col cols="7" class="d-flex flex-row">
        <div class="mt-8 mr-2">
          <icon class="text-h3 grey--text text--darken-1" id="select-properties"></icon>
        </div>
        <div style="width: 100%">
          <label class="text-caption grey--text text--darken-1">Select Properties</label>

          <v-combobox class="mt-1" v-model="selectedProperties" :items="normalDatasetSelectedGroupFieldsName" chips clearable label="Select Properties" multiple solo dense flat>
            <template v-slot:selection="{ attrs, item, select, selected }">
              <CChip class="my-1" v-bind="attrs" small :input-value="selected" close @click="select" @click:close="onRemoveSelectedProperties(item)"> {{ item }} &nbsp; </CChip>
            </template>
          </v-combobox>
        </div>
      </v-col>
      <v-col cols="5" class="d-flex flex-row">
        <div class="mt-8 mr-2">
          <icon class="text-h3 grey--text text--darken-1" id="select-standard-alloy"></icon>
        </div>
        <div style="width: 100%">
          <label class="ml-1 text-caption grey--text text--darken-1">Select Benchmark Alloy</label>
          <v-select
            class="mt-1"
            v-model="selectedStandardAlloyIds"
            :disabled="selectedProperties.length === 0"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="name"
            item-value="id"
            :items="standardAlloys"
            chips
            clearable
            label="Select Benchmark Alloy"
            multiple
            solo
            dense
            flat
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <CChip innerClass="my-1 white" color="primary" v-bind="attrs" small :input-value="selected" close @click="select" @click:close="onRemoveStandardAlloy(item)"> {{ item.name }} &nbsp; </CChip>
            </template>
          </v-select>
        </div>
      </v-col>
    </v-row>
    <div class="alloyComparisonChartContainer">
      <v-tabs class="alloyComparisonTabs" v-model="selectedChartTab" height="46px" >
        <v-tab class="font-weight-bold h5"> Bar Illustration </v-tab>
        <v-tab class="font-weight-bold h5"> Radar Graph </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedChartTab">
              <v-tab-item key="bar">
                <div class="d-flex flex-row mt-6">
                  <BarChartLegend :series="legendSeries"></BarChartLegend>
                  <div class="d-flex flex-wrap" style="width:100%">
                    <BarChart
                      v-for="(prp, idx) in selectedProperties"
                      :key="prp"
                      :width="350"
                      :propertyName="prp"
                      :ref="'bar' + idx"
                      :minColor="seriesColors.minColor"
                      :maxColor="seriesColors.maxColor"
                      :avgColor="seriesColors.avgColor"
                      :selectedColor="seriesColors.selectedColor"
                      :colors="seriesColors.stdandardColors"
                      :propertyValue="normalDatabasePropertyData.find((i) => i.name === prp).value"
                      :minValue="normalDatasetFields.find((i) => i.title === prp).stats.min"
                      :maxValue="normalDatasetFields.find((i) => i.title === prp).stats.max"
                      :avgValue="normalDatasetFields.find((i) => i.title === prp).stats.avg"
                      :standardAlloys="selectedStandardAlloyPropsBarChartData[prp] || new Set()"
                    ></BarChart>
                  </div>
                </div>

              </v-tab-item>
              <v-tab-item :eager="true" key="radar">
                <div id="chart">
                  <AlloyCompareRadar :properties="selectedProperties" :series="radarChartSeries" class="mt-n16 mb-n16" ref="radarChart"></AlloyCompareRadar>
                </div>
                <div v-if="selectedProperties.length <= 2">To show the radar graph, at least three properties are required to be selected.</div>
              </v-tab-item>
      </v-tabs-items>
    </div>

  </div>
</template>
<script>
import { getData } from "@/api/user/dataset";
import CChip from "@/components/Vuetify/CChip.vue";
import BarChart from "@/components/Predix/BarChart.vue";
import BarChartLegend from "@/components/Predix/BarChartLegend.vue";
import AlloyCompareRadar from "@/components/Predix/AlloyCompareRadar";
export default {
  components: {
    CChip,
    BarChart,
    BarChartLegend,
    AlloyCompareRadar,
  },
  name: "CastingAlloyComparison",
  props: {
    selectedNormalDatabaseId: {
      type: Number,
      default: -1,
    },
    selectedStandardDatabaseId: {
      type: Number,
      default: -1,
    },
    normalDatabasePropertyData: {
      type: Array,
      default: () => [],
    },
    standardAlloys: {
      type: Array,
      default: () => [],
    },
    package_id: {
      type: Number,
      default: -1,
    },
    normalDatasetFields: {
      type: Array,
      default: () => [],
    },
    fieldGroups: {
      type: Array,
      default: () => [],
    },
    selectedHeatTreatment: {
      type: Number,
      default: -1,
    },
    selectedCastingGroups: {
      type: Number,
      default: -1,
    },
  },
  data: () => ({
    selectedChartTab: null,
    currentTab: null,
    seriesBaseColors: {
      minColor: "#B6DCDD",
      maxColor: "#D6E1FF",
      avgColor: "#ECDEDB",
      selectedColor: "#038497",
      stdandardColors: [
        { dark: "#D88972", light: "#FEAD94" },
        { dark: "#E9A643", light: "#FFC671" },
        { dark: "#A47CD8", light: "#CAA6F8" },
        { dark: "#BFD08D", light: "#DFEFAF" },
        { dark: "#CBB086", light: "#E8D4B4" },
        { dark: "#AA8FC1", light: "#C4AFD6" },
        { dark: "#EEC4A2", light: "#FCE2CD" },
        { dark: "#97C9DA", light: "#C7E7F2" },
        { dark: "#C585B7", light: "#E3A0D5" },
        { dark: "#DAAFCD", light: "#F4D9EC" },
        { dark: "#93A4DE", light: "#93A4DE" },
        { dark: "#DFC785", light: "#F1DFAD" },
        { dark: "#689879", light: "#92BDA1" },
      ],
    },
    // colors: ["#9E9D24", "#EF5350", "#546E7A", "#3949AB", "#43A047", "#FB8C00", "#AB47BC", "#00ACC1", "#8D6E63", "#00897B", "#AEEA00", "#01579B"],
    // PACKAGE_ID: 9,
    // CASTING_GROUP:'CASTING',
    selectedStandardAlloyIds: [],
    standardAlloyProperties: {},
    selectedStandardAlloyProps: {},
    selectedProperties: [],
  }),
  computed: {
    seriesColors(){
      let baseColors = this.seriesBaseColors
      const darkness='b0'
      if(this.$vuetify.theme.isDark){
        baseColors.minColor += darkness
        baseColors.maxColor += darkness
        baseColors.avgColor += darkness
        baseColors.selectedColor += darkness
        baseColors.stdandardColors.forEach(item => {item.dark += darkness; item.light += darkness})
      }
      return baseColors
    },

    normalDatasetSelectedGroupFieldsName(){
      return this.normalDatabasePropertyData.filter(x => x.stats.min !== x.stats.max).map((item) => item.name);
    },
    legendSeries() {
      let x = [
        { name: "Min", color: this.seriesColors.minColor },
        { name: "Max", color: this.seriesColors.maxColor },
        { name: "Avg", color: this.seriesColors.avgColor },
        { name: "Selected", color: this.seriesColors.selectedColor },
      ];
      this.selectedStandardAlloyIds.forEach((alloyId, index) => {
        x.push({ name: alloyId, color: this.seriesColors.stdandardColors[index % this.seriesColors.stdandardColors.length].light });
      });
      // console.table(x);
      return x;
    },
    radarChartSeries(){
      let series = [];
      var selectedAlloy = { name: "Selected Alloy", data: [] };
      selectedAlloy.data = this.normalDatabasePropertyData.filter((item) => this.selectedProperties.includes(item.name)).map((x) => x.value);
      series.push(selectedAlloy);

       Object.keys(this.standardAlloyProperties).forEach((alloyId) => {
        //  const alloyId = this.selectedStandardAlloyIds[i];
         var s = { name: alloyId, data: [] };
         s.data = this.standardAlloyProperties[alloyId].filter((item) => this.selectedProperties.includes(item.id)).map((x) => x.value);
         series.push(s);

       }) 
       
       let minValues = this.selectedProperties.map((prp) => {
         return this.normalDatasetFields.find((item) => item.title === prp).stats.min;
        });
        let maxValues = this.selectedProperties.map((prp) => {
          return this.normalDatasetFields.find((item) => item.title === prp).stats.max;
       });
      
      if(series.length > 1){
          let min = minValues.slice()
          let max = maxValues.slice()
          for( let i=0; i < series.length; i++){
            for( let j=0; j < series[i].data.length; j++){
              min[j] = Math.min(series[i].data[j], min[j])
              max[j] = Math.max(series[i].data[j], max[j])
            }
          }
          for( let i=0; i < series.length; i++){
            for( let j=0; j < series[i].data.length; j++){
              if (max[j] === min[j]){
                series[i].data[j]= ( series[i].data[j] === 0 ? 0 : 1 )
              }else {
                series[i].data[j]= (series[i].data[j] - min[j])/(max[j] - min[j])
              }
            }
          }
        }
        else if(series.length == 1){
          series[0].data = series[0].data.map(item => item > 0 ? 1 : 0).slice()
        }
      return series
    },
    selectedStandardAlloyPropsBarChartData(){
      let tmp = {};
      Object.keys(this.standardAlloyProperties).forEach((alloyId) => {
        for (var i = 0; i < this.selectedProperties.length; i++) {
          const val = this.standardAlloyProperties[alloyId].find((item) => item.id === this.selectedProperties[i]).value;
          if (!tmp[this.selectedProperties[i]]) {
            tmp[this.selectedProperties[i]] = new Set();
          }
          tmp[this.selectedProperties[i]].add({ name: alloyId, value: val });
        }
      });
      return tmp
    }
  },
  watch: {
    selectedStandardAlloyIds(newList, oldList) {
      const _this = this;
      if (newList.length > oldList.length) {
        const groups = [_this.selectedCastingGroups, _this.selectedHeatTreatment];
        const newlyAddedAlloyId = newList[newList.length - 1];
        getData(this.package_id, this.selectedStandardDatabaseId, [{ f0: newlyAddedAlloyId }], groups, function (res, payload) {
          const alloy = res.result[0];
          const alloyId = payload.filters[0].f0;
          var props = [];
          for (var key of Object.keys(alloy).sort()) {
            props.push({ id: key, value: alloy[key] });
          }
          _this.$set(_this.standardAlloyProperties,alloyId, props);
        });
      } else if (newList.length < oldList.length){
        const removedAlloyIds = oldList.filter(item => newList.indexOf(item) < 0)
        removedAlloyIds.forEach(id => this.$delete(this.standardAlloyProperties ,id))
      }
    },
  },
  
  methods: {
    onRemoveStandardAlloy(alloyId) {
      console.log('H',alloyId)
      this.$delete(this.selectedStandardAlloyIds,this.selectedStandardAlloyIds.indexOf(alloyId.id))
      this.$delete(this.standardAlloyProperties ,alloyId.id)//THIS shit is due to strange WATCH behavior on selectedStandardAlloyIds in sending both old and new values the same
    },
    onRemoveSelectedProperties(item) {
      this.$delete(this.selectedProperties, this.selectedProperties.indexOf(item))
    },

    onResetProperties() {
      this.normalDatabasePropertyData = [];
      this.selectedStandardAlloyIds = [];
      this.standardAlloyProperties = {};
      this.selectedProperties = [];
    },
  },
};
</script>
<style scoped>
.alloyComparisonChartContainer {
  background-color: #ffffff94;
  border-radius: 6px;
  padding: 30px;
  margin: 10px 40px 40px 40px;
}

.theme--dark .alloyComparisonChartContainer {
  background-color: #18191A66;
}


::v-deep .v-item-group{
  background-color: white  !important;
}
.theme--dark ::v-deep .v-item-group{
  background-color: #18191A66  !important;
}
.alloyComparisonTabs {
  box-shadow: 0px 7px 64px 0px #00000012;
  /* background-color: #18191A66  !important; */
  border-radius: 6px;
}

::v-deep .v-tab {
  text-transform: none !important;
}
::v-deep .v-tabs-items{
  background-color: transparent !important;
}
</style>