<template>
  <v-data-table
    :headers="exploreResultHeader"
    :items="exploreResult"
    :disable-pagination="true"
    :items-per-page="50"
    :hide-default-footer="true"
    class="elevation-0"
    :expanded.sync="expanded"
    item-key="name"
    show-expand
    style="background-color: transparent"
  >
    <template v-slot:item.index="{ item }">
      {{ exploreResult.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.value="{ item }">
      <span>{{ pruneNumber(item.value) }}</span>
    </template>
    <template v-slot:item.stats="{ item }">
      <CastingExporeResultStatistics :min="item.stats.min" :max="item.stats.max" :val="item.value"></CastingExporeResultStatistics>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <AlloyPropertyAnalytics
          class="pt-0 pb-8"
          :prpName="item.name"
          :seriesData="[
            { name: 'Min', value: item.stats.min, type: 'MIN' },
            { name: 'Avg', value: item.stats.avg, type: 'AVG' },
            { name: 'Max', value: item.stats.max, type: 'MAX' },
            { name: 'Selected Alloy', value: item.value, type: 'PRP' },
          ]"
          :standardAlloys="standardAlloys"
          :standardDatasetName="standardDatasetName"
          :package_id="package_id"
          :groups="item.groups"
          :standardDatasetId="standardDatasetId"
        >
        </AlloyPropertyAnalytics>
        <!-- {name: item.name , value: item.value, type: 'PRP'} -->
      </td>
    </template>
    <template v-slot:item.data-table-expand="{ isExpanded , expand}">
      <v-icon large color="primary" @click="expand(!isExpanded)">{{ isExpanded ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
    </template>
  </v-data-table>
</template>


<script>
import AlloyPropertyAnalytics from "@/components/Predix/AlloyPropertyAnalytics";
import CastingExporeResultStatistics from "@/components/Predix/CastingExploreResultStatistics.vue";
import { smartPruneNumber } from "@/utils/number";
export default {
  components: { AlloyPropertyAnalytics, CastingExporeResultStatistics },
  name: "CastingExploreResult",
  data: () => ({
    exploreResultHeader: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "index",
      },
      {
        text: "Alloy Property",
        sortable: true,
        align: "center",
        value: "name",
        cellClass: "text-h5 ",
      },

      { text: "Predicted Value", value: "value", sortable: false, align: "center" },
      { text: "Statistics", value: "stats", sortable: false, align: "center" },
      { text: "Benchmark", align: "center", value: "data-table-expand", width: 40 },
    ],
    expanded: [],
  }),

  props: {
    exploreResult: {
      type: Array,
      default: () => [],
    },
    standardAlloys: {
      type: Array,
      default: () => [],
    },
    standardDatasetName: {
      type: String,
      default: "",
    },
    standardDatasetId: {
      type: Number,
      default: -1,
    },
    package_id: {
      type: Number,
      default: -1,
    },
    castingPropertiesGroupId: {
      type: Number,
      default: 0,
    },
    castingPropertiesGroupName: {
      type: String,
      default: "",
    },
  },
  created() {
    this.exploreResultHeader[1].text = "Alloy " + this.castingPropertiesGroupName;
    console.log(this.exploreResultHeader[1].text);
  },
  methods: {
    getIndicatorPosition(min, max, value, length) {
      const bias = 10;
      if (min == max || value == min) {
        return bias;
      } else if (value > max) {
        return bias + length;
      } else if (value < min) {
        return bias;
      } else {
        return bias + (value * length) / (max - min);
      }
    },
    pruneNumber(num) {
      return smartPruneNumber(num);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-data-table-header {
  background-color: var(--v-primary-base) !important;
  border-radius: 6px !important;
}

.theme--dark ::v-deep .v-data-table-header {
  background-color: #00424B !important;
  // border-radius: 6px !important;
}

// .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) { 
// .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover { 
//   .v-table tbody tr:not(:last-child) { 
//   background: red !important;
//  } 

::v-deep tbody tr:hover:not(.v-data-table__expanded__content)  {
  background: #00000050 !important;

}

.v-data-table::v-deep th {
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
}

// .v-data-table:not(v-data-table__expanded__row)::v-deep  > td {
.v-data-table::v-deep td {
  border-bottom: 1px solid var(--v-primary-base) !important;
  // height: 40px;
  vertical-align: top;
  padding-top: 15px !important;
}

.v-data-table::v-deep .v-data-table__expanded__row td {
  border-bottom: 0px !important;
  vertical-align: top;
  padding-top: 15px !important;
}



.v-data-table::v-deep .v-data-table__expanded__content {
  box-shadow: none !important;
}

.propertyMinMax {
  // font-family: "Courier New", Courier, monospace;
  font-size: x-small;
  font-weight: bold;
  color: gray;
}

.statindicators {
  font-size: 11px;
  color: rgb(95, 93, 88);
}
</style>