<template>
<div  >
    <v-app-bar elevate-on-scroll flat outlined fixed height="80px" class="pr-4 ml-16" color="white"  >
        <v-toolbar-title class="pl-10 title text--secondary font-weight-bold" >
          <v-icon large>{{titleIcon}}</v-icon> {{title }} 
        </v-toolbar-title>
          <v-spacer></v-spacer>
    <v-toolbar-items >
      <!-- style="background: linear-gradient(to left, rgba(255, 205, 147), rgba(64, 157, 149, 0))" -->
        <v-tabs  right color="orange darken-1" class="pt-4"  >
          <template v-for="item in menuItems" >
          <v-tab :key="item.title" class="font-weight-bold" ripple :to="{ name: item.routeName, params: item.routeParams }">{{item.title}}</v-tab>
          <svg :key="'k'+item.title" width="15" class="ml-3 ">
            <line x1="15" y1="0" x2="0" y2="50" style="stroke:rgb(251,140,0);stroke-width:2" />
          </svg>
          </template>
        </v-tabs>  
    </v-toolbar-items>
    </v-app-bar>

</div>
</template>

<script>
  export default {
    name: 'NavBar',
    props: {
      title: String(),
      titleIcon: String(),
      menuItems:[]
    }
  }
</script>
<style>
.v-toolbar__content{
    /* border-bottom: 8px solid; */
    border-bottom-width: 1px;
    border-color: rgb(255, 205, 147);
}
</style>