<template>
  <div :style="cssVars">
    <label class="text-caption labelColor" v-if="cLabel">{{ cLabel }}</label>
    <v-text-field class="mt-1" :class="changed ? 'changed' : ''"   outlined v-bind="$attrs" v-on="$listeners"> 
        <slot/>
</v-text-field>
</div>
</template>

<script>
export default {
  name: "CTextField",
  props: {
    changed: {
      type:Boolean,
      default: false,
    },
    cLabel:{
      type: String,
      default: null
    },
    border:{
      type: String,
      default: '0px'
    }
    
  },
  computed:{
    cssVars(){
      return {'--border-size':this.border}
    }
  }
};
</script>

<style scoped>

.labelColor{
  color: rgba(107, 117, 136, 1);
}

.theme--dark .labelColor{
  /* color: rgb(223, 223, 223); */
  color: #6B7588;
}

.v-text-field::v-deep input {
  color: rgba(107, 117, 136, 1) !important;  
}

::v-deep .v-input__control {
  background-color: white !important;
  box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.09);
 /* box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07); */
}

::v-deep .theme--dark .v-input__control {
  background-color: #18191A !important;
  box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.09);
 /* box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07); */
}


.v-text-field.error--text:not(.v-input--is-focused) ::v-deep input {
  color:  rgba(175, 26, 34, 1) !important;
}

.v-text-field.changed.error--text:not(.v-input--is-focused) ::v-deep input {
  color: rgba(175, 26, 34, 1) !important;
}

.v-text-field.changed:not(.error--text) ::v-deep input {
  color: black !important;
}
.theme--dark .v-text-field.changed:not(.error--text) ::v-deep input {
  color: white !important;
}

.v-text-field {
  padding-top: 0px !important;
  
}
.v-text-field--outlined:not(.v-input--is-focused):not(.error--text)::v-deep fieldset {
  border: var(--border-size) solid rgba(234, 234, 234, 1) !important;
}

.theme--dark .v-text-field--outlined:not(.v-input--is-focused):not(.error--text)::v-deep fieldset {
  border: var(--border-size) solid #6B7588 !important;
  /* border: var(--border-size) solid blue !important; */
}

.theme--dark  .v-text-field--outlined.changed:not(.v-input--is-focused):not(.error--text)::v-deep  fieldset  {
  border: none !important;
}

.v-text-field--outlined.v-input--is-focused::v-deep fieldset {
  border: 1px solid var(--v-primary-base) !important;
}

::v-deep .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>