<template>
  <div id="stage-parent">
    <div class="text-caption mb-2 ml-4 grey--text text--darken-1">
      <span v-if="exponent != 0">
        ×10<sup>{{ this.exponent }}</sup></span
      >
    </div>
    <v-stage :config="configKonva">
      <v-layer>
        <v-text v-for="(t, index) in yAxisLabels" :key="'t' + index" :config="t"></v-text>
        <v-line :config="yAxis" />
        <v-line v-for="(x, index) in yGrid" :config="x" :key="'r' + index" />
        <v-rect :config="lightBar"></v-rect>
        <v-rect :config="darkBar"></v-rect>
        <BarChartMarker
          :markerHeight="markerHeight"
          dir="LEFT"
          v-for="(item, index) in rightMarker"
          :key="'bc' + index"
          :isSelected="item.isSelected"
          :name="item.name"
          :from="{ x: 90, y: item.tunedValue }"
          :to="{ x: 170, y: item.value }"
          :color="item.color"
        ></BarChartMarker>
        <BarChartMarker
          :markerHeight="markerHeight"
          dir="RIGHT"
          v-for="(item, index) in leftMarkers"
          :key="'lc' + index"
          :isSelected="item.isSelected"
          :name="item.name"
          :from="{ x: 250, y: item.tunedValue }"
          :to="{ x: 220, y: item.value }"
          :color="item.color"
        ></BarChartMarker>
      </v-layer>
    </v-stage>
    <div class="text-caption text-center pl-12" :style="'width:' + width + 'px'">{{ propertyName }}</div>
  </div>
</template>
<script>
import BarChartMarker from "@/components/Predix/BarChartMarker.vue";
import { toExp, pruneNumber } from "@/utils/number.js";
const STEP_COUNT = 8;
const Y_AXIS_START_X = 80;
const BAR_LEFT_PADDING = 180;
const BAR_WIDTH = 35;
// const RIGHT_MARKER_START=90
export default {
  name: "BarChart",
  components: { BarChartMarker },
  data: () => ({
    sceneWidth: 1000,
    sceneHeight: 1000,

    markerHeight: 22,
    // stepHeight: 50,
    // stepCount: 8,
    // yAxisStartX: 80,
  }),
  props: {
    propertyName: {
      type: String,
      default: " PhaseComposition Fe wt% FCC_A1[Al]   ",
    },
    propertyValue: {
      type: Number,
      default: 100,
    },
    minValue: {
      type: Number,
      default: 36,
    },
    maxValue: {
      type: Number,
      default: 180,
    },
    avgValue: {
      type: Number,
      default: 38,
    },
    standardAlloys: {
      type: Set,
      default: () => null,
    },
    minColor: {
      type: String,
      default: "#B6DCDD",
    },
    maxColor: {
      type: String,
      default: "#D6E1FF",
    },
    avgColor: {
      type: String,
      default: "#ECDEDB",
    },
    selectedColor: {
      type: String,
      default: "#038497",
    },
    colors: {
      type: Array,
      default: () => [
        { dark: "#D88972", light: "#FEAD94" },
        { dark: "#E9A643", light: "#FFC671" },
        { dark: "#A47CD8", light: "#CAA6F8" },
        { dark: "#BFD08D", light: "#DFEFAF" },
        { dark: "#CBB086", light: "#E8D4B4" },
        { dark: "#AA8FC1", light: "#C4AFD6" },
        { dark: "#EEC4A2", light: "#FCE2CD" },
        { dark: "#97C9DA", light: "#C7E7F2" },
        { dark: "#C585B7", light: "#E3A0D5" },
        { dark: "#DAAFCD", light: "#F4D9EC" },
        { dark: "#93A4DE", light: "#93A4DE" },
        { dark: "#DFC785", light: "#F1DFAD" },
        { dark: "#689879", light: "#92BDA1" },
      ],
    },
    height: {
      type: Number,
      default: 350,
    },
    width: {
      type: Number,
      default: 400,
    },
  },
  computed: {
    configKonva() {
      return {
        width: this.width,
        height: this.height + 10,
      };
    },
    leftMarkers() {
      let x = Array.from(this.standardAlloys).map((item, index) => {
        item.isSelected = false;
        item.color = this.colors[index % this.colors.length].light;
        return item;
      });
      return this.rescaleMarkers(x);
    },
    rightMarker() {
      let x = [
        { name: "Min", value: this.minValue, color: this.minColor, isSelected: false },
        { name: "Max", value: this.maxValue, color: this.maxColor, isSelected: false },
        { name: "Avg", value: this.avgValue, color: this.avgColor, isSelected: false },
        { name: "Selected", value: this.propertyValue, color: this.selectedColor, isSelected: true },
      ];
      // console.log('BEFORE',x)
      let z = this.rescaleMarkers(x);
      // console.log('AFTER',z)
      return z;
    },
    stepHeight() {
      return Math.round(this.height / STEP_COUNT);
    },
    yMin() {
      if (this.standardAlloys.size > 0) {
        return Math.min(this.minValue, this.propertyValue, ...Array.from(this.standardAlloys).map((item) => item.value));
      } else {
        return Math.min(this.minValue, this.propertyValue);
      }
    },
    yMax() {
      if (this.standardAlloys.size > 0) {
        return Math.max(this.maxValue, this.propertyValue, ...Array.from(this.standardAlloys).map((item) => item.value));
      } else {
        return Math.max(this.maxValue, this.propertyValue);
      }
    },
    exponent() {
      return toExp(this.yMax).exp;
    },
    yAxisLabels() {
      const stepSize = (this.yMax - this.yMin) / STEP_COUNT;
      let res = [];
      for (var i = 0; i < STEP_COUNT + 1; i++) {
        res.push({
          x: 0,
          y: (STEP_COUNT - i) * (this.stepHeight - 1),
          text: pruneNumber((this.yMin + i * stepSize) * Math.pow(10, -1 * this.exponent), 3),
          fontSize: 12,
          fontFamily: "IBM Plex sans",
          fill: "#6B7588",
          width: 70,
          padding: 0,
          align: "right",
        });
      }
      // console.log(res)
      return res;
    },
    yAxis() {
      return {
        x: 0,
        y: 0,
        points: [Y_AXIS_START_X, 0, Y_AXIS_START_X, this.stepHeight * STEP_COUNT],
        stroke: "black",
        strokeWidth: 0.2,
      };
    },
    yGrid() {
      let res = [];
      for (var i = 0; i < STEP_COUNT + 1; i++) {
        res.push({
          x: 0,
          y: 0,
          points: [Y_AXIS_START_X, i * this.stepHeight, 500, i * this.stepHeight],
          stroke: "black",
          strokeWidth: 0.15,
          dash: i == STEP_COUNT ? null : [3, 3],
        });
      }
      return res;
    },
    lightBar() {
      return { x: BAR_LEFT_PADDING, y: 0, width: BAR_WIDTH, height: this.height + 1, strokeWidth: 0, fill: "#86DAE5" };
    },
    darkBar() {
      const h = this.getRealH(this.propertyValue);
      return { x: BAR_LEFT_PADDING, y: this.height - h, width: BAR_WIDTH, height: h + 1, strokeWidth: 0, fill: "#038497" };
    },
  },
  methods: {
    toExp: toExp,
    rescaleMarkers(x) {
      if (x.length == 0) {
        return;
      }
      x.sort((a, b) => a.value - b.value);
      x = x.map((item) => {
        item.value = this.height - this.getRealH(item.value);
        item.bucketIndex = Math.floor(item.value / (this.markerHeight + 1)) + 1;
        item.tunedValue = item.bucketIndex * this.markerHeight;
        return item;
      });
      // console.table(x)
      x.forEach((item, index, arr) => {
        if (index > 0 && arr.slice(0, index).findIndex((m) => m.bucketIndex == item.bucketIndex) != -1) {
          // console.log('CHANGE', arr.slice(0,index))
          item.bucketIndex = Math.min(...arr.slice(0, index).map((c) => c.bucketIndex)) - 1;
          item.tunedValue = item.bucketIndex * this.markerHeight;
        }
      });
      // console.log('MIDDLE')
      // console.table(x)
      const minBucketIndex = x[x.length - 1].bucketIndex;
      if (minBucketIndex <= 0) {
        for (var j = x.length - 1; j >= 0; j--) {
          x[j].bucketIndex = x[j].bucketIndex + Math.abs(minBucketIndex) + 1;
          x[j].tunedValue = x[j].bucketIndex * this.markerHeight;
          if (j == 0 || x[j].bucketIndex < x[j - 1].bucketIndex) {
            break;
          }
        }
      }
      // console.table(x)

      return x;
    },
    getRealH(val) {
      // return this.height * (this.yMax - val - this.yMin)/(this.yMax - this.yMin)
      return (this.height * (val - this.yMin)) / (this.yMax - this.yMin);
    },
  },
};

//RESPONSIVE https://konvajs.org/docs/sandbox/Responsive_Canvas.html
</script>

<style scoped>
#stage-parent {
  /* width: 100%; */
}
</style>