
<template>
  <div>
    <v-row>
      <v-col cols="7" >
        <div class="chartBox">
          <AnalyticsChart :internalSeriesData="internalSeriesData"></AnalyticsChart>
        </div>
      </v-col>
      <v-col cols="5" >
        <div class="filterBox pa-4">
          <div>Benchmarck Against:</div>
          <div class="d-flex flex-row mt-2">
            <div style="width:40px">
              <icon class="text-h3 mt-2 mr-1" id="benchmark-alloy-list"></icon>
            </div>
            <v-combobox v-model="selectedStandardAlloys" :items="standardAlloyNames" chips clearable label="Standard Alloy" multiple solo dense flat>
              <template v-slot:selection="{ attrs, item, select, selected }">
                <CChip class="my-1 "  v-bind="attrs" small :input-value="selected" close @click="select" @click:close="remove(item)">
                  {{ item }} &nbsp;
                </CChip>
              </template>
            </v-combobox>

          </div>
        </div>
      </v-col>
    </v-row>
    
    <!-- <v-row>
      <v-col cols="8" id="chart">

        <apexchart type="bar" :height="height" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
// import VueApexCharts from 'vue-apexcharts'
import { gradient } from '@/utils/color'
import { getData} from '@/api/user/dataset'
import  CChip from '@/components/Vuetify/CChip.vue'
import  AnalyticsChart from '@/components/Predix/AnalyticsChart.vue'
 

export default {
  name: 'AlloyPropertyAnalytics',
   components: {
          // apexchart: VueApexCharts, 
          CChip, AnalyticsChart
    },  
    props:{
      seriesData:[],
      standardAlloys:[],
      groups:[],
      package_id: {
        type: Number,
        default:0
      },
      standardDatasetName: {
        type: String,
        default:''
      },
      standardDatasetId:{
        type: Number,
        default:0
      },
      prpName: {
        type: String,
        default: ''
      }
    },
    watch: {
      selectedStandardAlloys(newList, oldList){
        if(newList.length > oldList.length)
        {
          var newAlloy = this.standardAlloys.find(item => item.name === newList[newList.length - 1])
          const _this = this
          getData(this.package_id, this.standardDatasetId, [ {"f0": newAlloy.id}],this.groups, function(res){
                const al = res.result[0][_this.prpName]
                _this.internalSeriesData.push( {"name": newAlloy.name, "value":al, type:"STANDARD"})
                _this.loadChart()
          })           
        } else {
          const diff = oldList.filter(item => newList.indexOf(item) == -1)
          diff.forEach(element => {
            var removedAlloyIdx = this.internalSeriesData.findIndex(item => item.name == element  ) //&& item.type === 'STANDARD'
            if(removedAlloyIdx > -1){
              this.internalSeriesData.splice(removedAlloyIdx, 1)
            }  
          });
          this.loadChart()
        }
      }
    },
    computed: {
      standardAlloyNames(){
        return this.standardAlloys.map(item => item.name)
      }
    },
    methods: {
      onReset(){
        this.selectedStandardAlloys=[]
        this.internalSeriesData = this.internalSeriesData.filter(item => item.type !== 'STANDARD')
        this.loadChart()
       },
      loadChart(){
      const dt = this.internalSeriesData.sort((a,b) => { 
        if(a.value < b.value) {
          return -1
        } 
        if(a.value > b.value) {
          return 1
        } 
        return 0
        })
      this.series[0].data = dt.map(item => item.value)
      this.chartOptions.xaxis.categories = dt.map(item => item.name)
      var m = this.chartOptions.xaxis.categories.length
      this.height = m * 50
      const start = '#96ead7'
      const end = '#165e4e'
      const stdStart = '#5591ca'
      const stdEnd = '#163b5e'
      this.chartOptions.colors = []
      for (var i = 0; i< m; i++){
        if ( dt[i].type === 'PRP' ){
          this.chartOptions.colors.push('#ff9800')
        } else if ( dt[i].type === 'STANDARD' ){
          this.chartOptions.colors.push(gradient(i/m, stdStart, stdEnd))
        }else {
          this.chartOptions.colors.push(gradient(i/m, start, end))
        }
      }

    },         
      remove (item) {
        console.log('remove', item)
        let temp = this.selectedStandardAlloys.filter(it => it !== item)
        this.selectedStandardAlloys = temp
      },
    },
    data: () => ({
    selectedStandardAlloys:[],
    internalSeriesData:[],
    height: 200,
    series: [{
            data: []
          }],
    chartOptions: {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          barHeight: '90%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      colors: [],
      dataLabels: {
        enabled: true,
        textAnchor: 'end',
        style: {
          colors: ['#444']
        },
        formatter: function (val) {
          if(val % 1 === 0 ){
            return  val
            // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          } else{
            return  val.toPrecision(6)
            // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toPrecision(6)
          }
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
          color: '#fff',
          top: 0,
          left: 0,
          blur: 1,
          opacity: 1
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      title: {
          text: '',
          align: 'center',
          floating: true
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
    }
  })
    ,
    
    created(){
      this.chartOptions.title.text = this.prpName 
      this.internalSeriesData =  this.seriesData
      this.loadChart()

    }
}
</script>
<style >
.filterBox {
 background-color: rgba(3, 147, 168, 0.09);
 border-radius: 4px;
 height: 100%;
}
.chartBox{
  border: 1px solid   rgba(134, 218, 229, 1);
 border-radius: 4px;

}
</style>