<template>
<div  >
    <!--<v-app-bar flat outlined fixed height="80px" class="px-4" color="white"  >
        <v-toolbar-title class="pl-3 title text--secondary font-weight-bold" >
          <v-icon>mdi-account</v-icon> {{user.first_name + ' ' + user.last_name }} 
        </v-toolbar-title>
          <v-spacer></v-spacer>
    <v-toolbar-items >
        <v-tabs  right color="orange darken-1" class="pt-4"  >
          <v-tab class="font-weight-bold" ripple :to="{ name:'userInfo', params:{ id:$route.params.id } }">PROFILE</v-tab>
          <svg width="15" class="mx-2">
            <line x1="15" y1="0" x2="0" y2="50" style="stroke:rgb(251,140,0);stroke-width:2" />
          </svg>
          <v-tab class="font-weight-bold" ripple :to="{ name:'userDatasets', params:{ id:$route.params.id } }">GRANTED DATABASES</v-tab>
          <svg width="15" class="mx-2">
            <line x1="15" y1="0" x2="0" y2="50" style="stroke:rgb(251,140,0);stroke-width:2" />
          </svg>
          <v-tab class="font-weight-bold" ripple :to="{ name:'grantDatasets', params:{ id:$route.params.id } }">NEW GRANT</v-tab>
        </v-tabs>  
    </v-toolbar-items>
    </v-app-bar>
    -->
<main> 
    <NavBar :title="user.first_name + ' ' + user.last_name" 
            :titleIcon="'mdi-account'"
            :menuItems= menuItems
            />
    <v-container class="pt-10 mt-10" >
          <v-slide-x-transition mode="out-in">
            <router-view/>
          </v-slide-x-transition>
    </v-container>
</main>
</div>
</template>

<script>
import {getUser} from '@/api/users'
import NavBar from '@/components/NavBar.vue'
  export default {
    name: 'ManageUser',
    components: { NavBar},
    data () {
      return {
        menuItems: [ 
          { title:'PROFILE', routeName:'userInfo', routeParams:{ id: this.$route.params.id }} ,
          { title:'GRANTED DATABASES', routeName:'userDatasets', routeParams:{ id:this.$route.params.id }} ,
          { title:'NEW GRANT', routeName:'grantDatasets', routeParams:{ id:this.$route.params.id }} 
          ],
        user:{
          first_name:'',
          last_name:'',
        }
      }
    },
    created () {
      const _this = this
      getUser(this.$route.params.id, function(ds){
        _this.user = ds
      })
    }
  }
</script>