<template>
  <div class="d-flex flex-column mt-2">
    <apexchart ref="myChart" id="myChart" type="radar" height="800" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { primaryColors, secondaryColors } from "@/utils/color.js";
export default {
  name: "AlloyCompareBar",
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 900,
          type: "radar",
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        tooltip: {
          enabled: true,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          x: {
            show: true,
          },
        },
        stroke: {
          width: 2,
          dashArray: this.series.map((item) => (item.name == "Selected Alloy" ? 4 : 0)),
        },
        fill: {
          opacity: 0.2,
        },
        legend: {
          showForSingleSeries: true,
          show: true,
          position: "left",
          floating: true,
          verticalAlign: "center",
          fontFamily: "IBM Plex sans",
          align: "center",
          labels: {
            colors: [this.$vuetify.theme.isDark ? "#EBEAFA" : "#070707"],
          },
          // color:'red',
          fontSize: "12px",
          offsetX: 10,
          offsetY: 100,
          markers: {
            radius: 0,
            width: 13,
            height: 13,
            offsetY: 2,
          },
        },
        markers: {
          size: 0,
        },
        colors: Array.prototype.concat(["#0393A8"], primaryColors.dark, secondaryColors.dark).map(item => (this.$vuetify.theme.isDark? item+'70' : item )),
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: this.$vuetify.theme.isDark?'#d0d0d020' :'#d0d0d050',
              // size: 150,
              fill: {
                  colors: ['#f8f8f810', '#0f0f0f10']
              }
            }
          }
        },
        
        xaxis: {
          categories: this.properties,
          labels: {
            style: {
              fontFamily: "IBM Plex sans",
              fontSize: "12px",
              colors: this.$vuetify.theme.isDark ? Array(this.properties.length).fill("#EBEAFA") : Array(this.properties.length).fill("#070707"),
            },
          },
        },
        yaxis: {
          show: true,
          tickAmount: 10,
          min: 0,
          max: 1,
          labels: {
            show: true,
            formatter: (value) => {
              return Math.round(value * 100) + "%";
            },
            style: {
              fontFamily: "IBM Plex sans",
              fontSize: "12px",
              colors: this.$vuetify.theme.isDark ? Array(11).fill("#EBEAFA") : Array(11).fill("#070707"),
            },
          },
        },
      };
    },
    // series(){

    // }
  },
  props: {
    properties: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // updateChart: function(properties, propertyValueOfAlloys, minValues, maxValues){
    //   if(propertyValueOfAlloys.length > 1){
    //     let min = minValues.slice()
    //     let max = maxValues.slice()
    //     for( let i=1; i < propertyValueOfAlloys.length; i++){
    //       for( let j=0; j < propertyValueOfAlloys[i].data.length; j++){
    //         min[j] = Math.min(propertyValueOfAlloys[i].data[j], min[j])
    //         max[j] = Math.max(propertyValueOfAlloys[i].data[j], max[j])
    //       }
    //     }
    //     for( let i=0; i < propertyValueOfAlloys.length; i++){
    //       for( let j=0; j < propertyValueOfAlloys[i].data.length; j++){
    //         if (max[j] === min[j]){
    //           propertyValueOfAlloys[i].data[j]= ( propertyValueOfAlloys[i].data[j] === 0 ? 0 : 1 )
    //         }else {
    //           propertyValueOfAlloys[i].data[j]= (propertyValueOfAlloys[i].data[j] - min[j])/(max[j] - min[j])
    //         }
    //       }
    //     }
    //   }
    //   else if(propertyValueOfAlloys.length == 1){
    //     propertyValueOfAlloys[0].data = propertyValueOfAlloys[0].data.map(item => item > 0 ? 1 : 0).slice()
    //   }
    //   const colors = Array(properties.length).fill('#070707')
    //     var tmpXaxis = { categories: properties,
    //                         labels: {
    //                         style: {
    //                             fontSize: '14px',
    //                             colors: colors
    //                         }
    //                       }
    //                   }
    //     this.$refs.myChart.updateOptions({ xaxis: tmpXaxis})
    //     this.$refs.myChart.updateSeries(propertyValueOfAlloys)
    // }
  },
};
</script>
