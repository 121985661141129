 <template>
    <div class="px-3 pt-3">
        <div  v-for="(item, index) in dataSeries" :key="index" class="bar" :style="'background-color:'+item.color.light" >
             
            <div class="innerBar" :style="'background-color:'+item.color.dark + '; width:' + item.innerWidthPercent + '%;'  ">
            </div>
            <div class="nameValueContainer " :style="'width:' + item.valueLabelPosition + '%;'">
                <div class="d-flex flex-row justify-space-between">
                    <div class="nameLabel " :class="item.class">{{ item.name }}</div>
                    <div class="valueLabel" :class="item.class" > {{ item.prunedValue }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
 <script>
import { smartPruneNumber } from "@/utils/number";
1041
 export default {
    name:'AnalyticsChart',
    props:{
        internalSeriesData: {
            type: Array, 
            required :true
        }
    },
    computed:{
        dataSeries(){
            // const min = this.internalSeriesData.find(d => d.type=='MIN').value
            // const max = this.internalSeriesData.find(d => d.type=='MAX').value
            const min = Math.min(...this.internalSeriesData.map(item => item.value))
            const max = Math.max(...this.internalSeriesData.map(item => item.value))
            return this.internalSeriesData.map((item,index) => {
               var x= item
               x.class="nonPropClass"
               switch(item.type){
                case 'MIN':
                    x.color= this.minColor
                    break;
                case 'MAX':
                    x.color= this.maxColor
                    break;
                case 'AVG':
                    x.color= this.avgColor
                    break;                    
                case 'PRP':
                    x.color= this.prpColor
                    x.class="propClass"
                    break;
                default:
                    x.color= this.colors[index % this.colors.length]
                    break;
               }
               x.prunedValue = smartPruneNumber(x.value)
               x.innerWidthPercent= ((x.value - min) / (max - min)) * 100
               x.valueLabelPosition = Math.min(x.innerWidthPercent + 4, 96)
               return x
            })
        },
        minColor(){
            return { dark:this.darkenColor(this.minBaseColor.dark), light:this.darkenColor(this.minBaseColor.light,'90'),}
        },
        avgColor(){
            return { dark:this.darkenColor(this.avgBaseColor.dark), light:this.darkenColor(this.avgBaseColor.light,'90'),}
        },
        maxColor(){
            return { dark:this.darkenColor(this.maxBaseColor.dark), light:this.darkenColor(this.maxBaseColor.light,'90'),}
        },
        prpColor(){
            return { dark:this.darkenColor(this.prpBaseColor.dark), light:this.darkenColor(this.prpBaseColor.light,'90'),}
        },
        colors(){
            return this.baseColors.map(item => { return{ dark: this.darkenColor(item.dark), light:this.darkenColor(item.light,'90')}})
        },
    },  
    methods:{
        darkenColor(color, degree='c0'){
            if(this.$vuetify.theme.isDark){
                return color + degree
            } else {
                return color
            }
        }
    },
    data:()=>({
        minBaseColor:{dark:'#9BE795', light:'#C1FFBB'},
        avgBaseColor:{dark:'#7BB695', light:'#AFE7C8'},
        maxBaseColor:{dark:'#EC9999',light:'#FFBBBB'},
        prpBaseColor:{dark:'#4BAAC7', light:'#75D0EB'},

        baseColors:[
            {dark:'#D88972',light:'#FEAD94'}, 
            {dark:'#E9A643',light:'#FFC671'},
            {dark:'#A47CD8',light:'#CAA6F8'}, 
            {dark:'#BFD08D',light:'#DFEFAF'},
            {dark:'#CBB086',light:'#E8D4B4'},
            {dark:'#AA8FC1',light:'#C4AFD6'},
            {dark:'#EEC4A2',light:'#FCE2CD'},
            {dark:'#97C9DA',light:'#C7E7F2'},
            {dark:'#C585B7',light:'#E3A0D5'},
            {dark:'#DAAFCD',light:'#F4D9EC'},
            {dark:'#93A4DE',light:'#BFCDFF'},
            {dark:'#DFC785',light:'#F1DFAD'},
            {dark:'#689879', light:'#92BDA1'}
        ],
    }),    
 }
 </script>
 <style  scoped>
 .bar{
    border-radius: 0px 2px 2px 0px;
    padding: 10px;
    height:26px;
    margin-bottom: 12px;
    position: relative;
    /* transition: width 2s; */
    /* opacity: 0.9; */
    
}

.nonPropClass{
    color:black;
}
.propClass{
    color:white;
    font-weight:700;
    text-shadow: #999999 1px 0 3px;
}

/* .theme--dark .nonPropClass{
    color:black;
} */
.theme--dark .propClass{
    color:black;
    font-weight:700;
    /* text-shadow: #999999 1px 0 3px; */
}


.innerBar{
    position: absolute;
    left: 0;
    top:0;
    border-radius: 0px 4px 4px 0px;
    height:26px;
    font-size: 12px;
    padding-top: 4px;
    padding-left: 3px;
    /* background: radial-gradient(circle, #75D0EB 10%, transparent 11%),radial-gradient(circle at bottom left, #75D0EB 5%, transparent 6%),radial-gradient(circle at bottom right, #75D0EB 5%, transparent 6%),radial-gradient(circle at top left, #75D0EB 5%, transparent 6%),radial-gradient(circle at top right, #75D0EB 5%, transparent 6%);
    background-size: 1em 1em; */

    /* width: 50%; */
    /* transition: width 2s; */
    /* transition-delay: 1s ; */

    /* overflow-anchor: none; */
    /* word-wrap: initial; */
}

.nameValueContainer{
     white-space: nowrap;
     position: absolute;
     left: 5px;
     top:4px;
     font-size: 12px;

 }
 .valueLabel{
    padding-left: 10px;
 }
 </style>