import ApiIntegrface from "./api"
export function authenticate(username, password, callback, errHandler){
  const api = new ApiIntegrface()
  const url = "/api/v1/token/"
  const payload= { 'username' : username, 'password' : password}
  api.post(url, payload, callback, errHandler)
}

export function verifyToken(token, callback, errHandler){
  const api = ApiIntegrface()
  const url = '/api/v1/token/verify/'
  const payload = { 'token' : token}
  api.post(url, payload, callback, errHandler)
}

export function refreshToken(refreshToken, callback, errHandler){
  const api = ApiIntegrface()
  const url = '/api/v1/token/refresh/'
  const payload = { 'refresh' : refreshToken}
  api.post(url, payload, callback, errHandler)
}

export function deleteUser(userId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId
  api.delete(url, null, callback, null, successMessage)
}

export function addUser(payload, callback, errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users'
  api.post(url, payload, callback, errhandler, successMessage)
}


export function getUser(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId
  api.get(url, null, callback)
}

export function getUsers(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users'
  api.get(url, null, callback)
}

export function changePassword(id, oldPassword, newPassword1, newPassword2, callback,errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + id + '/set_password'
  const payload ={
    oldPassword:  oldPassword,
    newPassword1: newPassword1, 
    newPassword2: newPassword2
  }
  api.post(url, payload, callback, errhandler, successMessage)
}


export function updateUser(userId, payload, callback,  errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId
  api.put(url, payload, callback, errhandler, successMessage)
}

export function getUserPackages(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/packages'
  api.get(url, null, callback)
}

export function getUserPackageDatasets(userId, packageId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/packages/' + packageId + '/datasets'
  api.get(url, null, callback)
}


export function getUserDatasets(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/datasets'
  api.get(url, null, callback)
}

export function grantAccessToDatasets(userId, packageId, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/packages/'+ packageId +'/datasets'
  api.post(url, payload, callback, null, successMessage)
}

export function revokeAccessToDatasets(userId, packageId, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/packages/'+ packageId +'/datasets/batch'
  api.delete(url, payload, callback, null, successMessage)
}