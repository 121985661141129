export function pruneNumber(num , precision = 6){
  if (num % 1 === 0){ // is integer
    return num /1
  }
  return num.toFixed(precision)/1
}

export function smartPruneNumber(num){
  if (num % 1 === 0){ // is integer
    return num /1
  }
  const precision = Math.min( Math.abs(Math.min(Math.floor(Math.log10(num)) + (-2),0)),6)
  return num.toFixed(precision)/1
}

export function toExp(n){
  const s=n.toExponential()
  const e=s.indexOf('e')
  return {base: smartPruneNumber(Number(s.substr(0,e))), exp: Number(s.substr(e+1)) };
}