<template>
  <v-chip :class="innerClass" close-icon="mdi-close" close v-bind="$attrs" v-on="$listeners" :color="cColor">
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: "CChip",
  props:{
    color:{
      type: String,
      default: null
    },
    innerClass:{
      type: String,
      default: 'defaulClass'
    }
  },
  computed:{
    cColor(){
      if(! this.color){
        if(this.$vuetify.theme.isDark){
          return '#00424B'
        } else {
          return 'rgba(210, 243, 245, 1)'
        }
        // return 'blue'
      } else {
        return this.color
      }
    },
    cInnerClass(){
      if(! this.innerClass){
        return  'primary--text'
      } else {
        return this.innerClass
      }

    }
  }
};
</script>
<style scoped>

.defaulClass{
  color:var(--v-primary-base)
}

.theme--dark .defaulClass{
  color: #86DAE5;

}
.v-chip.v-size--small {
  border-radius: 2px !important;
}
</style>